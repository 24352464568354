var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('side-bar',{attrs:{"user":_vm.user,"miniVariant":_vm.miniVariant},on:{"value-change":function($event){_vm.sidebar = $event}},model:{value:(_vm.sidebar),callback:function ($$v) {_vm.sidebar=$$v},expression:"sidebar"}}),_c('sup-navbar',{attrs:{"items":_vm.page?.breadcrumb,"user":_vm.user},on:{"click-burger":function($event){return _vm.handlerClickBurger()},"open-config":function($event){_vm.configModal = true},"open-help":function($event){_vm.helpModal = true}}}),_c('v-main',{staticClass:"grey lighten-4"},[_c('v-container',{class:[_vm.page?.container == 'fluid' ? 'pa-0' : 'not-extended']},[(_vm.page.hideTitle !== undefined && !_vm.page?.hideTitle)?_c('v-row',{class:{
          'd-flex align-center mx-3 mt-3': true,
          'flex-wrap': _vm.isMobile,
        }},[(!_vm.page?.hideTitle)?_c('h2',{class:{
            'font-weight-bold primary--text text--darken-4 my-6': true,
            'text-h6 text-center flex-grow-1': _vm.isMobile,
            'text-h4': !_vm.isMobile,
          }},[_c('v-icon',{attrs:{"left":"","large":!_vm.isMobile,"color":"primary"}},[_vm._v("mdi-"+_vm._s(_vm.page?.icon))]),_vm._v(" "+_vm._s(_vm.page?.title ?? "")+" ")],1):_vm._e(),(!_vm.isMobile)?_c('v-spacer'):_vm._e(),(_vm.page?.breadcrumb?.length > 1)?_c('div',{class:{ 'flex-item flex-grow-1 text-center': _vm.isMobile }},[_c('v-btn',{attrs:{"text":"","small":_vm.isMobile,"color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'Home' })}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left-bold-outline")]),_vm._v(" Ir al inicio ")],1)],1):_vm._e()],1):_vm._e(),_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('router-view')],1)],1),_c('base-snackbar',{attrs:{"text":_vm.snackbar.text,"snackbar-active":_vm.snackbar.active,"timeout":_vm.snackbar.timeout,"color":_vm.snackbar.color,"position":{
        top: _vm.snackbar.top,
        bottom: _vm.snackbar.bottom,
        right: _vm.snackbar.right,
        left: _vm.snackbar.left,
        centered: _vm.snackbar.centered,
      }},on:{"close":function($event){return _vm.closeSnackbar()}}}),_c('config-modal',{on:{"close":function($event){_vm.configModal = false}},model:{value:(_vm.configModal),callback:function ($$v) {_vm.configModal=$$v},expression:"configModal"}}),_c('v-dialog',{attrs:{"scrollable":"","max-width":"500px"},on:{"click:outside":function($event){_vm.helpModal = false}},model:{value:(_vm.helpModal),callback:function ($$v) {_vm.helpModal=$$v},expression:"helpModal"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Soporte técnico "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","x-small":"","text":"","fab":""},on:{"click":function($event){_vm.helpModal = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_vm._v(" Bienvenido a nuestro servicio de soporte técnico. Estamos aquí para ayudarte. ¿Tienes alguna pregunta o problema? Nuestro equipo de soporte está listo para brindarte asistencia. Por favor, ingresa en el siguiente enlace al hacer click "),_c('v-btn',{attrs:{"color":"primary","x-small":"","text":""},on:{"click":_vm.openContactForm}},[_vm._v("aquí")]),_vm._v(". "),_c('br'),_vm._v(" ¡Gracias por elegirnos! ")],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"color":"primary","text":"","small":""},on:{"click":_vm.openContactForm}},[_vm._v(" ir a formulario de Soporte "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-open-in-new")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }