<template>
  <v-app-bar app elevation="4">
    <v-app-bar-nav-icon @click="$emit('click-burger')"></v-app-bar-nav-icon>
    <!-- BREADCRUMBS -->
    <v-breadcrumbs :items="items" class="font-weight-bold">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <v-spacer></v-spacer>

    <v-btn
      color="primary"
      small
      text
      fab
      class="mr-2"
      @click="$emit('open-help')"
      ><v-icon>mdi-help-circle</v-icon></v-btn
    >

    <v-btn
      color="primary"
      fab
      text
      small
      class="mr-2"
      @click="$emit('open-config')"
    >
      <v-icon>mdi-cog</v-icon>
    </v-btn>

    <!-- USER OPTIONS -->
    <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar v-on="on" v-bind="attrs" size="36" color="primary darken-4">
          <img
            :src="userPhotoUrl"
            :alt="user?.[userKeys.data.fullName] ?? 'foto de usuario'"
        /></v-avatar>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar color="primary">
              <img
                :src="userPhotoUrl"
                :alt="user?.[userKeys.data.fullName] ?? 'foto de usuario'"
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{
                user?.[userKeys.data.fullName]
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list dense>
          <v-list-item>
            <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
            <v-list-item-title>Perfil</v-list-item-title>
          </v-list-item>

          <v-list-item @click="logout()">
            <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
            <v-list-item-title>Salir</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { userKeys } from "@/store/resources/userkeys.js";
export default {
  name: "SuperiorNavbar",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    userKeys: () => userKeys,
    userPhotoUrl() {
      return `https://api.dicebear.com/7.x/initials/svg?seed=${
        this.user?.[this.userKeys.name]
      }%20${this.user?.[this.userKeys.lastName1]}&backgroundColor=transparent`;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    config() {
      this.$emit("open-config");
    },
  },
};
</script>
