<template>
  <v-dialog
    :value="value"
    scrollable
    :overlay="false"
    max-width="550px"
    transition="dialog-transition"
    @click:outside="$emit('close')"
  >
    <v-card>
      <v-card-title primary-title>
        Config. de la Aplicación
        <v-spacer></v-spacer>
        <v-btn color="primary" x-small fab text @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <h4 class="text-h6">Tema</h4>
        <v-divider class="mt-1 mb-3"></v-divider>
        <v-slide-group :value="theme" @change="setTheme" show-arrows mandatory>
          <v-slide-item
            v-for="n in themesList.length"
            :key="n"
            v-slot="{ active, toggle }"
            class="mx-2"
          >
            <v-card
              @click="toggle"
              elevation="5"
              :color="active ? 'primary' : 'grey lighten-1'"
              class="pa-2"
              height="92"
            >
              <v-img :src="`/templates/theme-${n - 1}.jpg`" width="140">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
\

<script>
import Themes from "@/plugins/themes.js";
export default {
  name: "ConfigModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    theme() {
      const numero = parseInt(this.$store.getters.GetTheme);
      return !isNaN(numero) ? numero : 3;
    },
    themesList: () => Object.keys(Themes) ?? [],
  },
  methods: {
    setTheme(e) {
      this.$store.commit("setTheme", e);
    },
  },
};
</script>
