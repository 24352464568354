<template>
  <v-app>
    <!-- SIDE BAR MENU -->
    <side-bar
      :user="user"
      v-model="sidebar"
      :miniVariant="miniVariant"
      @value-change="sidebar = $event"
    ></side-bar>
    <!-- SUP NAVBAR -->
    <sup-navbar
      @click-burger="handlerClickBurger()"
      @open-config="configModal = true"
      @open-help="helpModal = true"
      :items="page?.breadcrumb"
      :user="user"
    />
    <!-- CONTENT -->
    <v-main class="grey lighten-4">
      <v-container
        :class="[page?.container == 'fluid' ? 'pa-0' : 'not-extended']"
      >
        <!-- PAGE TITLE -->
        <v-row
          :class="{
            'd-flex align-center mx-3 mt-3': true,
            'flex-wrap': isMobile,
          }"
          v-if="page.hideTitle !== undefined && !page?.hideTitle"
        >
          <h2
            :class="{
              'font-weight-bold primary--text text--darken-4 my-6': true,
              'text-h6 text-center flex-grow-1': isMobile,
              'text-h4': !isMobile,
            }"
            v-if="!page?.hideTitle"
          >
            <v-icon left :large="!isMobile" color="primary"
              >mdi-{{ page?.icon }}</v-icon
            >
            {{ page?.title ?? "" }}
          </h2>

          <v-spacer v-if="!isMobile"></v-spacer>
          <div
            :class="{ 'flex-item flex-grow-1 text-center': isMobile }"
            v-if="page?.breadcrumb?.length > 1"
          >
            <v-btn
              text
              :small="isMobile"
              color="primary"
              @click="$router.push({ name: 'Home' })"
            >
              <v-icon left>mdi-arrow-left-bold-outline</v-icon> Ir al inicio
            </v-btn>
          </div>
        </v-row>

        <v-fade-transition mode="out-in">
          <!-- TRANSITION ANIMATION -->
          <router-view />
        </v-fade-transition>
      </v-container>
      <!-- NOTIFICATION -->
      <base-snackbar
        :text="snackbar.text"
        :snackbar-active="snackbar.active"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        :position="{
          top: snackbar.top,
          bottom: snackbar.bottom,
          right: snackbar.right,
          left: snackbar.left,
          centered: snackbar.centered,
        }"
        @close="closeSnackbar()"
      />

      <!-- MODAL DE CONFIGURACIONES DE LA APLICACION -->
      <config-modal @close="configModal = false" v-model="configModal" />

      <!-- MODAL DE AYUDA -->
      <v-dialog
        v-model="helpModal"
        scrollable
        max-width="500px"
        @click:outside="helpModal = false"
      >
        <v-card>
          <v-card-title primary-title>
            Soporte técnico <v-spacer></v-spacer>
            <v-btn @click="helpModal = false" color="primary" x-small text fab>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            Bienvenido a nuestro servicio de soporte técnico. Estamos aquí para
            ayudarte. ¿Tienes alguna pregunta o problema? Nuestro equipo de
            soporte está listo para brindarte asistencia. Por favor, ingresa en
            el siguiente enlace al hacer click
            <v-btn color="primary" x-small text @click="openContactForm"
              >aquí</v-btn
            >. <br />
            ¡Gracias por elegirnos!
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn color="primary" text small @click="openContactForm">
              ir a formulario de Soporte
              <v-icon right>mdi-open-in-new</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import SideBar from "@/components/Bars/LeftBarMenu";
import BaseSnackbar from "@/components/Snackbar/BaseSnackbar.vue";
import ConfigModal from "@/components/Modal/configModal.vue";
import SupNavbar from "@/components/Bars/SupNavBar.vue";

export default {
  name: "DashboardLayout",
  components: {
    SideBar,
    BaseSnackbar,
    ConfigModal,
    SupNavbar,
  },
  data: () => ({
    sidebar: false,
    miniVariant: false,
    configModal: false,
    helpModal: false,
  }),
  mounted() {
    if (this.sidebarCache) {
      this.sidebarCache === "open"
        ? (this.miniVariant = false)
        : (this.miniVariant = true);
    } else if (this.$vuetify.breakpoint.mdAndDown && !this.isMobile) {
      this.miniVariant = true;
    }
  },
  computed: {
    page() {
      return this.$store.getters.GetPage;
    },
    snackbar() {
      return this.$store.getters.GetSnackbar;
    },
    user() {
      return this.$store.getters.UserData;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    sidebarCache() {
      return localStorage.getItem("sidebar");
    },
  },
  methods: {
    closeSnackbar() {
      this.$store.commit("setSnackbar", { active: false });
    },
    handlerClickBurger() {
      this.setSidebarCache(
        this.isMobile ? this.miniVariant : !this.miniVariant
      );
      this.isMobile
        ? (this.sidebar = !this.sidebar)
        : (this.miniVariant = !this.miniVariant);
    },
    openContactForm() {
      const formLink =
        "https://forms.zohopublic.com/mesoschile/form/SoporteSistemas/formperma/pUMVVrHDN8ybFlv6EUhLzt8i7_j84_fXYNZKa3BTMIM";
      window.open(formLink, "_blank");
    },
    setSidebarCache(value) {
      localStorage.setItem("sidebar", value ? "closed" : "open");
    },
  },
};
</script>
